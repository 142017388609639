import Login from "@/components/Login";
import Restore from "@/components/Restore";
import Registration from "@/components/Registration";
import EndRegistration from "@/components/EndRegistration";
import PasswordRecovery from "@/components/PasswordRecovery";
import MainScreen from "@/components/MainScreen";
import Error from "@/components/Error"
// import Billing from "@/components/Billing/Billing"
// import NavbarAccount from "@/components/Account/NavbarAccount";
// import Account from "@/components/Account/Account";
// import ProfileDetails from "@/components/Account/ProfileDetails";
// import ProfileSecurity from "@/components/Account/ProfileSecurity";
// import Notifications from "@/components/Account/Notifications";
// import ProfileCompany from "@/components/Account/ProfileCompany";
// import BillingDetails from "@/components/Account/BillingDetails";
// import BillingContacts from "@/components/Account/BillingContacts";
// import TeamInvitation from "@/components/Account/TeamInvitation";
// import Teams from "@/components/Account/Teams";
// import TeamsDetails from "@/components/Account/TeamsDetails";
// import NavbarIntegration from "@/components/Integration/NavbarIntegration";
import Integration from "@/components/Integration/Integration/Integration";
import AddDomains from "@/components/Integration/AddDomains"
// import PartnerSource from "@/components/Integration/PartnerSource";
// import AddPartnerSource from "@/components/Integration/AddPartnerSource";
// import FirstRegisterDomains from "@/components/Integration/FirstRegisterDomains";
// import SecondRegisterDomains from "@/components/Integration/SecondRegisterDomains";
// import ThirdRegisterDomains from "@/components/Integration/ThirdRegisterDomains";
// import ApiDocuments from "@/components/Integration/ApiDocuments";
// import NavbarStatistics from "@/components/Statistics/NavbarStatistics"
// import StatisticsSummary from "@/components/Statistics/StatisticsSummary"
import store from "@/store";

const ifNotAuthenticated = (to, from, next) => {
  if (!store.state.auth.isAuthenticated) {
    next()
    return
  }
  next('/login')
}

const ifAuthenticated = (to, from, next) => {
  if (store.state.auth.isAuthenticated) {
    next()
    return
  }
  next('/login')
}

const check = (to, from, next) => {
  store.dispatch('auth/check')
    .then(() => {
      next()
    })
    .catch(() => {
      next('/login')
    })
}

export default [
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/restore',
    name: 'restore',
    component: Restore
  },
  {
    path: '/register',
    name: 'register',
    component: Registration,
  },
  {
    path: '/endRegister',
    name: 'endRegister',
    component: EndRegistration
  },
  {
    path: '/passwordRecovery/:hash',
    name: 'passwordRecovery',
    component: PasswordRecovery
  },
  {
    path: '/error',
    name: 'error',
    component: Error
  },
  {
    path: '/main',
    name: 'mainScreen',
    component: MainScreen,
    children: [
  //     {
  //       path: '/account',
  //       name: 'account',
  //       component: Account,
  //       beforeEnter: check, ifAuthenticated,
  //     },
  //     {
  //       path: '/billing',
  //       name: 'billing',
  //       component: Billing,
  //       beforeEnter: check, ifAuthenticated
  //     },
  //     {
  //       path: '/profileDetails',
  //       name: 'profileDetails',
  //       component: ProfileDetails,
  //       beforeEnter: check, ifAuthenticated,
  //     },
  //     {
  //       path: '/profileSecurity',
  //       name: 'profileSecurity',
  //       component: ProfileSecurity,
  //       beforeEnter: check, ifAuthenticated,
  //     },
  //     {
  //       path: '/notifications',
  //       name: 'notifications',
  //       component: Notifications,
  //       beforeEnter: check, ifAuthenticated,
  //     },
  //     {
  //       path: '/profileCompany',
  //       name: 'profileCompany',
  //       component: ProfileCompany,
  //       beforeEnter: check, ifAuthenticated,
  //     },
  //     {
  //       path: '/billingDetails',
  //       name: 'billingDetails',
  //       component: BillingDetails,
  //       beforeEnter: check, ifAuthenticated,
  //     },
  //     {
  //       path: '/billingContacts',
  //       name: 'billingContacts',
  //       component: BillingContacts,
  //       beforeEnter: check, ifAuthenticated,
  //     },
  //     {
  //       path: '/teamInvitation/:id',
  //       name: 'teamInvitation',
  //       component: TeamInvitation,
  //       beforeEnter: check, ifAuthenticated,
  //     },
  //     {
  //       path: '/teams',
  //       name: 'teams',
  //       component: Teams,
  //       beforeEnter: check, ifAuthenticated,
  //     },
  //     {
  //       path: '/teamsDetails/:id',
  //       name: 'teamsDetails',
  //       component: TeamsDetails,
  //       beforeEnter: check, ifAuthenticated,
  //     },
  //     {
  //       path: '/statisticsSummary',
  //       name: 'statisticsSummary',
  //       component: StatisticsSummary,
  //       beforeEnter: check, ifAuthenticated,
  //     },
      {
        path: '/integration',
        name: 'integration',
        component: Integration,
        beforeEnter: check, ifAuthenticated,
      },
      {
        path: '/addDomains',
        name: 'addDomains',
        component: AddDomains,
        beforeEnter: check, ifAuthenticated,
      },
  //     {
  //       path: '/firstRegisterDomains',
  //       name: 'firstRegisterDomains',
  //       component: FirstRegisterDomains,
  //       beforeEnter: check, ifAuthenticated,
  //     },
  //     {
  //       path: '/secondRegisterDomains',
  //       name: 'secondRegisterDomains',
  //       component: SecondRegisterDomains,
  //       beforeEnter: check, ifAuthenticated,
  //     },
  //     {
  //       path: '/thirdRegisterDomains',
  //       name: 'thirdRegisterDomains',
  //       component: ThirdRegisterDomains,
  //       beforeEnter: check, ifAuthenticated,
  //     },
  //     {
  //       path: '/apiDocuments',
  //       name: 'apiDocuments',
  //       component: ApiDocuments,
  //       beforeEnter: check, ifAuthenticated,
  //     }
    ]
  }
]
