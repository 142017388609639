import Http from "@/utils/http";
import Config from "@/config";

const state = {
  tokens: {},
  token: {},
  keyStatus: {},
  updateKey: null,
  keySettings: {},
  editKeySettings: {},
  resKeySettings: {}
};

const getters = {
  tokens: state => state.tokens,
  token: state => state.token,
  keyStatus: state => state.keyStatus,
  updateKey: state => state.updateKey,
  keySettings: state => state.keySettings,
  editKeySettings: state => state.editKeySettings,
  resKeySettings: state => state.resKeySettings
};

const mutations = {
  set: (state, tokens) => {
    state.tokens = tokens;
  },
  setKey: (state, token) => {
    state.token = token;
  },
  drop: (state, id) => {
    var newKey = [];
    state.tokens.map(e => {
      if (e.id != id) {
        newKey.push(e);
      }
    });
    state.token = newKey;
  },
  setKeyStatus: (state, keyStatus) => {
    state.keyStatus = keyStatus;
  },
  setUpdateKey: (state, updateKey) => {
    state.updateKey = updateKey;
  },
  setKeySettings: (state, keySettings) => {
    state.keySettings = keySettings;
  },
  setEditKeySettings: (state, editKeySettings) => {
    state.editKeySettings = editKeySettings
  },
  setResKeySettings: (state, resKeySettings) => {
    state.resKeySettings = resKeySettings
  }

};

const actions = {
  searchKey: ({commit}, data) => {
    return new Promise(resolve => {
      Http({
        method: "post",
        url: Config.apiURL + "/v1/user/team/domain/key/search",
        data: data,
        headers: {"Content-Type": "application/json"}
      })
        .then(resp => {
          commit("set", resp.data);
          resolve(resp);
        })
        .catch(err => {
          console.log("ERROR search KEYS " + err);
        });
    });
  },
  createKey: ({commit}, data) => {
    return new Promise(resolve => {
      Http({
        method: "post",
        url: Config.apiURL + "/v1/user/team/" + data.team_id + "/domain/" + data.domain_id + "/key/create",
        data: data,
        headers: {"Content-Type": "application/json"}
      })
        .then(resp => {
          commit("setKey", resp.data);
          resolve(resp);
        })
        .catch(err => {
          console.log("ERROR CREATE KEYS " + err);
        });
    });
  },
  deleteKey: ({commit}, data) => {
    return new Promise(resolve => {
      Http({
        method: "delete",
        url: Config.apiURL + "/v1/user/team/" + data.team_id + "/domain/" + data.domain_id + data.key_id + "/delete",
        data: data,
        headers: { "Content-Type": "application/json" }
      })
        .then(resp => {
          commit("drop");
          resolve(resp);
        })
        .catch(err => {
          console.log("ERRROR DELETE KEY " + err);
        });
    });
  },
  onKeyStatus: ({commit}, key) => {
    return new Promise(resolve => {
      Http({
        method: "patch",
        url: Config.apiURL + "/v1/user/team/" + key.teamId + "/domain/" + key.domainId + "/key/" + key.id + "/on",
        data: key,
        headers: { "Content-Type": "application/json" }
      })
        .then(resp => {
          commit("drop");
          resolve(resp);
        })
        .catch(err => {
          console.log("err on key " + err);
        });
    });
  },
  offKeyStatus: ({commit}, key) => {
    return new Promise(resolve => {
      Http({
        method: "patch",
        url: Config.apiURL + "/v1/user/team/" + key.teamId + "/domain/" + key.domainId + "/key/" + key.id + "/off",
        data: key,
        headers: { "Content-Type": "application/json" }
      })
        .then(resp => {
          commit("drop");
          resolve(resp);
        })
        .catch(err => {
          console.log("err off key " + err);
        });
    });
  },
  updateKey: ({commit}, updateKey) => {
    return new Promise(resolve => {
      Http({
        method: "put",
        url: Config.apiURL + "/v1/user/team/" + updateKey.teamId + "/domain/" + updateKey.domainId + "/key/" + updateKey.id,
        data: updateKey,
        headers: { "Content-Type": "application/json" }
      })
        .then(resp => {
          commit("setUpdateKey");
          resolve(resp);
        })
        .catch(err => {
          console.log("err updateKey " + err);
        });
    });
  },
  getKeySettings: ({commit}, keySettings) => {
    return new Promise(resolve => {
      Http({
        method: "get",
        url: Config.apiURL + "/v1/user/team/" + keySettings.teamId + "/domain/" + keySettings.domainId + "/key/" + keySettings.keyId + "/settings"
      })
        .then(resp => {
          commit("setKeySettings", resp);
          resolve(resp);
        })
        .catch(err => {
          console.log("ERROR " + err);
        });
    });
  },
  setKeySettings: ({commit}, settings) => {
    return new Promise(resolve => {
      Http({
        method: "post",
        url: Config.apiURL + "/v1/user/team/" + settings.settings[3].teamId + "/domain/" + settings.settings[2].domainId + "/key/" + settings.settings[1].keyId + "/setting",
        data: settings
      })
        .then(resp => {
          commit("setEditKeySettings", resp);
          resolve(resp);
        })
        .catch(err => {
          console.log("ERROR setKeySettings " + err);
        });
    });
  },
  resetKeySettings: ({commit}, dataRezSetting) => {
    return new Promise(resolve => {
      Http({
        method: 'delete',
        url: Config.apiURL + '/v1/user/team/' + dataRezSetting.teamId +'/domain/' + dataRezSetting.domainId + '/key/' + dataRezSetting.keyId + '/setting/' + dataRezSetting.id + '/reset'
      }).then(resp => {
        commit('setResKeySettings', resp)
        resolve(resp)
      }).catch(err => {
        console.log(err)
      })
    })
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
