import Config from "../config";
import Http from "@/utils/http";

const state = {
  apiURL: Config.apiURL,
  teams: [],
  updateTeam: null,
  selectedTeam: {},
  invitations: {},
  invitationsActive: {}
}

const getters = {
  apiURL: state => state.apiURL,
  teams: state => state.teams,
  updateTeam: state => state.updateTeam,
  selectedTeam: state => state.selectedTeam,
  invitations: state => state.invitations,
  invitationsActive: state => state.invitationsActive
}

const mutations = {
  setTeams: (state, teams) => {
    state.teams = teams
  },
  setUpdateTeam: (state, updateTeam) => {
    state.updateTeam = updateTeam
  },
  setSelectedTeam: (state, selected) => {
    state.selectedTeam = selected
  },
  setInvitations: (state, invitations) => {
    state.invitations = invitations
  },
  setInvitationsActive: (state, invitationsActive) => {
    state.invitationsActive = invitationsActive
  },
  dropInvitations: (state, id) => {
    var newInvitations = []
    state.invitations.map(i => {
      if (i.id != id) {
        newInvitations.push(i)
      }
    })
    state.invitations = newInvitations
  },
}

const actions = {
  selectTeam: ({commit}, id) => {
    if (id != null) {
      localStorage.TEAM_ID = id
    }

    var team = {}

    if (state.teams.length === 0) {
      return team
    }
    if (localStorage.TEAM_ID == null) {
      if (state.teams.length > 0) {
        team = state.teams[0]
      }

      localStorage.TEAM_ID = team.id
      commit('setSelectedTeam', team)
      return
    }

    var chosen = false
    state.teams.map(e => {
      if (e.id == localStorage.TEAM_ID) {
        team = e
        chosen = true
      }
    })

    if (chosen === false) {
      team = state.teams[0]
    }

    localStorage.TEAM_ID = team.id
    commit('setSelectedTeam', team)

  },
  createTeam: ({dispatch}, name) => {
    return new Promise((resolve) => {
      Http({
        method: 'post',
        url: Config.apiURL + '/v1/user/team/create',
        data: name,
        headers: {'Content-Type': 'application/json'},
      })
        .then(resp => {
          dispatch('setTeam')
          resolve(resp)
        })
        .catch(err => {
          console.log(err)
        })
    })
  },
  setTeam: ({commit}) => {
    return new Promise((resolve) => {
      Http({
        method: 'post',
        url: Config.apiURL + '/v1/user/team/search',
        data: {},
        headers: {'Content-Type': 'application/json'},
      })
        .then(resp => {
          commit('setTeams', resp.data)
          resolve(resp)
        })
        .catch(err => {
          console.log(err)
        })
    })
  },
  updateTeam: ({commit}, dataTeam) => {
    return new Promise((resolve) => {
      Http({
        method: 'put',
        url: Config.apiURL + '/v1/user/team/' + dataTeam.id,
        headers: {'Content-Type': 'application/json'},
        data: dataTeam
      })
        .then(resp => {
          commit('setUpdateTeam')
          resolve(resp)
        })
        .catch(err => {
          console.log(err)
        })
    })
  },
  deletedTeam: ({dispatch}, teamId) => {
    return new Promise((resolve) => {
      Http({
        method: 'delete',
        url: Config.apiURL + '/v1/user/team/' + teamId,
        headers: {'Content-Type': 'application/json'}
      })
        .then(resp => {
          dispatch('setTeam')
          resolve(resp)
        })
        .catch(err => {
          console.log(err)
        })
    })
  },
  restoreTeam: ({dispatch}, teamId) => {
    return new Promise((resolve) => {
      Http({
        method: 'PATCH',
        url: Config.apiURL + '/v1/user/team/' + teamId + '/restore',
        headers: {'Content-Type': 'application/json'}
      })
        .then(resp => {
          dispatch('setTeam')
          resolve(resp)
        })
        .catch(err => {
          console.log(err)
        })
    })
  },
  invitation: ({commit}, dataTeam) => {
    return new Promise((resolve) => {
      let formData = new FormData();
      formData.append('email', dataTeam.guestEmail);
      formData.append('serviceURLForMailMessage', dataTeam.serviceURLForMailMessage);
      Http({
        method: 'post',
        url: Config.apiURL + '/v1/user/team/' + dataTeam.team_id + '/invite',
        headers: {'Content-Type': 'multipart/form-data'},
        data: dataTeam
      })
        .then(resp => {
          commit('app/setMessagesEvent', resp.message, {root: true})
          resolve(resp)
        })
        .catch(err => {
          commit('app/setError', err.response.data.error.message, {root: true})
          commit('app/setMessagesEvent', err.response.data.error.message, {root: true})
        })
    })
  },
  invitationSearch: ({commit}, params) => {
    return new Promise((resolve) => {
      Http({
        method: 'post',
        url: Config.apiURL + '/v1/user/team/invitation/search',
        data: params,
        headers: {'Content-Type': 'application/json'},
      })
        .then(resp => {
          commit('setInvitations', resp.data)
          resolve(resp)
        })
        .catch(err => {
          console.log('ERROR SEARCH ' + err)
        })
    })
  },
  invitationSearchActive: ({commit}, params) => {
    return new Promise((resolve) => {
      Http({
        method: 'post',
        url: Config.apiURL + '/v1/user/team/invitation/search',
        data: params,
        headers: {'Content-Type': 'application/json'},
      })
        .then(resp => {
          commit('setInvitationsActive', resp.data)
          resolve(resp)
        })
        .catch(err => {
          console.log('ERROR SEARCH ' + err)
        })
    })
  },
  invitationCancel: ({commit}, invitationId) => {
    return new Promise(resolve => {
      Http({
        method: 'delete',
        url: Config.apiURL + '/v1/user/team/invitation/' + invitationId.id + '/cancel',
        data: invitationId,
        headers: {'Content-Type': 'application/json'},
      }).then(resp => {
        commit('dropInvitations', invitationId.id)
        resolve(resp)
      }).catch(err => {
        console.log('DELETE ERROR ' + err)
      })
    })
  },
  invitationAccept: ({commit}, invitationId) => {
    return new Promise((resolve) => {
      Http({
        method: 'patch',
        url: Config.apiURL + '/v1/user/team/invitation/' + invitationId.id + '/accept',
        data: invitationId
      })
        .then(resp => {
          commit('dropInvitations', invitationId.id)
          resolve(resp)
        })
        .catch(err => {
          commit('app/setError', err.response.data.error.message, {root: true})
        })
    })
  },
  invitationDecline: ({commit}, invitationId) => {
    return new Promise((resolve) => {
      Http({
        method: 'patch',
        url: Config.apiURL + '/v1/user/team/invitation/' + invitationId.id + '/decline',
        data: invitationId
      })
        .then(resp => {
          commit('dropInvitations', invitationId.id)
          resolve(resp)
        })
        .catch(err => {
          commit('app/setError', err.response.data.error.message, {root: true})
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
