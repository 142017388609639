import Http from "../utils/http";
import Config from "../config";

const state = {
  isAuthenticated: false,
  user: {},
  registerMessage: false,
  requestPasswordMessage: false,
  linkGoogle: {},
  linkGitHub: {},
  linkGoogleRegister: {},
  linkGitHubRegister: {}
};

const getters = {
  isAuthenticated: state => state.isAuthenticated,
  user: state => state.user,
  registerMessage: state => state.registerMessage,
  requestPasswordMessage: state => state.requestPasswordMessage,
  linkGoogle: state => state.linkGoogle,
  linkGitHub: state => state.linkGitHub,
  linkGoogleRegister: state => state.linkGoogleRegister,
  linkGitHubRegister: state => state.linkGitHubRegister

};

const mutations = {
    setIsAuthenticated: (state, isAuthenticated) => {
      state.isAuthenticated = isAuthenticated
    },
    setUser: (state, user) => {
      state.user = user
    },
    setRegisterMessage: (state, registerMessage) => {
      state.registerMessage = registerMessage
    },
    setRequestPasswordMessage: (state, requestPasswordMessage) => {
      state.requestPasswordMessage = requestPasswordMessage
    },
    setLinkGoogle: (state, linkGoogle) => {
      state.linkGoogle = linkGoogle
    },
    setLinkGitHub: (state, linkGoogle) => {
      state.linkGoogle = linkGoogle
    },
    setLinkGoogleRegister: (state, linkGoogleRegister) => {
      state.linkGoogleRegister = linkGoogleRegister
    },
    setLinkGitHubRegister: (state, linkGitHubRegister) => {
      state.linkGitHubRegister = linkGitHubRegister
    }
  }
;

const actions = {
  auth: ({commit, dispatch}, LoginForm) => {
    return new Promise(resolve => {
        let formData = new FormData();
        formData.append('email', LoginForm.email);
        formData.append('password', LoginForm.password);
        Http({
          method: 'post',
          url: Config.apiURL + "/guest/auth",
          data: formData,
          headers: {'Content-Type': 'multipart/form-data'}
        })
          .then(resp => {
            commit("setIsAuthenticated", true);
            dispatch('check')
            resolve(resp);
          })
          .catch((err) => {
            commit("setIsAuthenticated", false);
            commit('app/setError', err.response.data.error.message, {root: true})
          })
      }
    );
  },
  registry: ({commit}, registerData) => {
    return new Promise((resolve => {
      let formData = new FormData();
      formData.append('email', registerData.email);
      formData.append('serviceURLForMailMessage', registerData.serviceURLForMailMessage);
      Http({
        method: 'post',
        url: Config.apiURL + "/guest/registration",
        data: formData,
        headers: {'Content-Type': 'multipart/form-data'}
      })
        .then((resp) => {
          commit('setRegisterMessage', true)
          resolve(resp)
        })
        .catch((err) => {
          commit('app/setError', err.response.data.error.message, {root: true})
        })
    }))
  },
  check: ({commit, dispatch}) => {
    return new Promise((resolve, reject) => {
      Http.get(Config.apiURL + '/guest/check')
        .then(resp => {
          commit('setIsAuthenticated', true)
          commit('setUser', resp.data)
          dispatch('teams/invitationSearch', {guestsIDs: [resp.data.id]}, {root: true})
          resolve(resp)
        })
        .catch(err => {
          if (err.response) {
            commit('setIsAuthenticated', false)
            reject('error')
          }
        })
    })
  },
  updateUser: ({commit}, newDataUser) => {
    return new Promise(resolve => {
      Http({
        method: 'put',
        url: Config.apiURL + '/v1/user/update',
        headers: {'Content-Type': 'application/json'},
        data: newDataUser
      })
        .then(resp => {
          commit('setUser', resp.data)
          commit('app/setMessagesEvent', resp.message, {root: true})
          resolve(resp)
        })
        .catch(err => {
          commit('app/setMessagesEvent', err.response.data.error.message, {root: true})
        })
    })
  },
  requestPassword: ({commit}, recForm) => {
    return new Promise((resolve => {
      let formData = new FormData();
      formData.append('email', recForm.email);
      formData.append('serviceURLForMailMessage', recForm.serviceURLForMailMessage);

      Http({
        method: 'post',
        url: Config.apiURL + '/guest/recovery-password',
        data: formData,
        headers: {'Content-Type': 'multipart/form-data'}
      })
        .then((resp) => {
          commit('setRequestPasswordMessage', true)
          resolve(resp)
        })
        .catch((err) => {
          commit('app/setError', err.response.data.error.message, {root: true})
        })
    }))
  },
  changePassword: ({commit}, passForm) => {
    return new Promise((resolve => {
      let formData = new FormData();
      formData.append('hash', passForm.hash);
      formData.append('password', passForm.password);
      formData.append('passwordConfirmation', passForm.passwordConfirmation);
      Http({
        method: 'post',
        url: Config.apiURL + '/guest/change-password',
        data: formData,
        headers: {'Content-Type': 'multipart/form-data'}
      })
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          commit('app/setError', err.response.data.error.message, {root: true})
        })
    }))
  },
  userChangePassword: ({commit}, passForm) => {
    return new Promise((resolve => {
      let formData = new FormData();
      formData.append('password', passForm.password);
      formData.append('passwordConfirmation', passForm.passwordConfirmation);
      Http({
        method: 'post',
        url: Config.apiURL + '/v1/user/change-password',
        data: formData,
        headers: {'Content-Type': 'multipart/form-data'}
      })
        .then((resp) => {
          commit('app/setMessagesEvent', resp.message, {root: true})
          resolve(resp)
        })
        .catch((err) => {
          commit('app/setError', err.response.data.error.message, {root: true})
          commit('app/setMessagesEvent', err.response.data.error.message, {root: true})
        })
    }))
  },
  logout: ({commit, dispatch}) => {
    return new Promise((resolve) => {
      Http.get(Config.apiURL + '/v1/user/logout', {withCredentials: true})
        .then(resp => {
          commit('setIsAuthenticated', false)
          dispatch('check')
          resolve(resp)
        })
    })
  },
  googleAuth: ({commit}, dataRedirect) => {
    return new Promise(resolve => {
      Http({
        method: 'get',
        url: Config.apiURL + '/guest/oauth/google/login?callbackUrl=' + dataRedirect.callbackUrl + '&clientRedirectUrl=' + dataRedirect.clientRedirectUrl,
        data: dataRedirect
      }).then(resp => {
        commit('setLinkGoogle', resp)
        resolve(resp)
      }).catch(err => {
        console.log('googleAuth ERROR ' + err)
      })
    })
  },
  githubAuth: ({commit}, dataRedirect) => {
    return new Promise(resolve => {
      Http({
        method: 'get',
        url: Config.apiURL + '/guest/oauth/github/login?callbackUrl=' + dataRedirect.callbackUrl + '&clientRedirectUrl=' + dataRedirect.clientRedirectUrl,
        data: dataRedirect
      }).then(resp => {
        commit('setLinkGitHub', resp)
        resolve(resp)
      }).catch(err => {
        console.log('googleAuth ERROR ' + err)
      })
    })
  },
  googleRegister: ({commit}, dataRedirect) => {
    return new Promise(resolve => {
      Http({
        method: 'get',
        url: Config.apiURL + '/guest/registration/google?callbackUrl=' + dataRedirect.callbackUrl + '&redirectUrl=' + dataRedirect.redirectUrl,
        data: dataRedirect
      }).then(resp => {
        commit('setLinkGoogleRegister', resp)
        resolve(resp)
      }).catch(err => {
        console.log('googleRegister ERROR ' + err)
      })
    })
  },
  githubRegister: ({commit}, dataRedirect) => {
    return new Promise(resolve => {
      Http({
        method: 'get',
        url: Config.apiURL + '/guest/registration/github?callbackUrl=' + dataRedirect.callbackUrl + '&redirectUrl=' + dataRedirect.redirectUrl,
        data: dataRedirect
      }).then(resp => {
        commit('setLinkGitHubRegister', resp)
        resolve(resp)
      }).catch(err => {
        console.log('googleRegister ERROR ' + err)
      })
    })
  },
};


export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
