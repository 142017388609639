export default {
  methods: {
    isActive(route, parentName, childName) {
      for (let a of route) {
        if (a.name == parentName && a.name == childName) {
          return true
        }
        if (a.name == parentName) {
          if (childName == null) {
            return true
          }
          if (a.children != null && this.isActive(a.children, childName, null)) {
            return true
          }
        } else {
          if (a.children != null && this.isActive(a.children, parentName, childName)) {
            return true
          }
        }
      }
      return false
    }
  }
}
