<template>
  <div class="sing-in">
    <div class="auth-card">
      <div class="logo">
        <img src="../images/svg/logo-icon-bg-blue60.svg" alt="logo"/>
      </div>
      <div class="form-login">
        <form @submit.prevent="createRequestPassword">
          <div class="form-login-content">
            <span class="form-login-title font-h2 color_white">{{ localize('restore_title_reset_password') }}</span>
            <div class="input-field">
              <input
                class="input-email"
                id="email"
                v-model="email"
                type="email"
                :placeholder="localize('login_email_input')"
                :class="{invalid: ($v.email.$dirty && !$v.email.required) || ($v.email.$dirty && !$v.email.email)}"/>
              <label class="visually-hidden" for="email">{{ localize('restore_email_input') }}</label>
            </div>
            <div class="block-error">
              <div class="text-error">{{ requestPasswordMessages() }}</div>
              <span class="text-error" v-if="$v.email.$dirty && !$v.email.required">{{ localize('restore_invalid-field') }}</span>
              <span class="text-error" v-if="$v.email.$dirty && !$v.email.email">{{ localize('restore_invalid-email') }}</span>
            </div>
          </div>
          <div class="form-login-action">
            <div class="form-login-btn form-login-btn-reset">
              <button class="button button-white">{{ localize('restore_reset_password') }}</button>
            </div>
            <div class="form-login-reset">
              <router-link to="/login" title="">{{ localize('restore_remembered') }}</router-link>
            </div>
          </div>
        </form>
      </div>
      <div class="link-register">
        <span>{{ localize('restore_dont_have_account') }}</span>
        <router-link class="links-white" to="/register">{{ localize('restore_sign_up_for_free') }}</router-link>
      </div>
    </div>
    <div class="background-img">
      <div class="logo">
        <img src="../images/png/logo-icon-text.png" alt="logo tlprt-media">
      </div>
    </div>
  </div>

</template>

<script>
import i18n from "@/filters/localize";
import {email, required} from 'vuelidate/lib/validators'
import Vuex from "vuex";

export default {
  name: 'restore',
  mixins: [i18n],
  data() {
    return {
      email: '',
      messages: ''
    }
  },
  validations: {
    email: {email, required}
  },
  methods: {
    ...Vuex.mapGetters({
      error: 'app/error',
    }),
    ...Vuex.mapActions({
      requestPassword: 'auth/requestPassword'
    }),
    createRequestPassword() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.requestPassword({
        email: this.email,
        serviceURLForMailMessage: origin
      })
        .then(() => {
          this.$router.push('/endRegister')
        })
    },
    requestPasswordMessages() {
      return this.messages = this.error()
    }
  }
}

</script>
