const state = {
  locale: '45'
}

const getters = {
   locale: state => state.locale
}

const mutations = {
  setLocale({state}, locale) {
    state.locale = locale
  }
}

const actions = {

}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
