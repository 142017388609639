<template>
  <div class="end-registration">
    <div class="content">
      <div class="text">
        <div class="registration" v-if="registerPassword">
          <span class="text-title">{{ localize('end-message_register-title') }}</span>
          <span class="text-messages">{{ localize('end-message_register-discription') }}</span>
        </div>
        <div class="password" v-if="requestPassword">
          <span class="text-title">{{ localize('end-message_password-title') }}</span>
          <span class="text-messages">{{ localize('end-message_password-discription') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/filters/localize";
import Vuex from 'vuex'

export default {
  name: 'endRegister',
  mixins: [i18n],
  computed: {
    ...Vuex.mapGetters({
      registerPassword: 'auth/registerMessage',
      requestPassword: 'auth/requestPasswordMessage'
    })
  }
}
</script>
