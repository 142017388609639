import Http from "@/utils/http";
import Config from "@/config";

const state = {
  statistics: {},
  discret: 60
}

const getters = {
  statistics: state => state.statistics,
  discret: state => state.discret
}

const mutations = {
  setStatistics: (state, statistics) => {
    state.statistics = statistics
  },
  setDiscret: (state, discret) => {
    state.discret = discret
  },
}

const actions = {
  findStatisticsDay: ({commit}, dataStatistics) => {
    return new Promise(resolve => {
      Http({
        method: 'post',
        url: Config.apiURL + '/v1/user/team/' + dataStatistics.teamId + '/statistics/day',
        data: dataStatistics
      }).then(resp => {
        commit('setStatistics', resp.data)
        commit('setDiscret', 60)
        resolve(resp)
      }).catch(err => {
        console.log('ERROR findStatisticsDay ' + err)
      })
    })
  },
  findStatisticsWeek: ({commit}, dataStatistics) => {
    return new Promise(resolve => {
      Http({
        method: 'post',
        url: Config.apiURL + '/v1/user/team/' + dataStatistics.teamId + '/statistics/week',
        data: dataStatistics
      }).then(resp => {
        commit('setStatistics', resp.data)
        commit('setDiscret', 1200)
        resolve(resp)
      }).catch(err => {
        console.log('ERROR findStatisticsWeek ' + err)
      })
    })
  },
  findStatisticsMonth: ({commit}, dataStatistics) => {
    return new Promise(resolve => {
      Http({
        method: 'post',
        url: Config.apiURL + '/v1/user/team/' + dataStatistics.teamId + '/statistics/month',
        data: dataStatistics
      }).then(resp => {
        commit('setStatistics', resp.data)
        commit('setDiscret', 3600)
        resolve(resp)
      }).catch(err => {
        console.log('ERROR findStatisticsMonth ' + err)
      })
    })
  },
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
