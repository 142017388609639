import axios from 'axios'

axios.defaults.withCredentials = true

const Http = axios.create({
    withCredentials: true
})

/** Добавление перехватчиков ответа */
Http.interceptors.response.use(
    response => {
        /** Добавить перехватчики ответа */
        /** Любой код состояния, который находится в диапазоне 2xx, вызывает срабатывание этой функции. */
        if ([200, 201].includes(response.status)) {
            return Promise.resolve(response.data)
        } else if ([401].includes(response.status)) {
            this.$store.auth.commit('setIsAuthenticated', false)
            this.$route.push('/')
        } else {
            return Promise.reject(response.data)
        }
        return response;
    },
    error => {
        /** Обработать ошибку в ответе */
        /** Любые коды состояния, выходящие за пределы диапазона 2xx, вызывают срабатывание этой функции */
        if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
        } else if (error.request) {
            console.log(error.request);
        } else {
            console.log('Error', error.message);
        }
        return Promise.reject(error)
    }
);

export default Http






