import Http from "@/utils/http";
import Config from "@/config";

const state = {
  domains: [],
  vrfDomain: {},
  domainStatus: {},
  domainHash: {},
  dataDomainVerifi: {},
  checkedStatistic: []
};

const getters = {
  domains: state => state.domains,
  vrfDomain: state => state.vrfDomain,
  domainStatus: state => state.domainStatus,
  domainHash: state => state.domainHash,
  dataDomainVerifi: state => state.dataDomainVerifi,
  checkedStatistic: state => state.checkedStatistic
};

const mutations = {
  setCheckedStatistic: (state, checkedStatistic) => {
    let arrayCheckedStatisticVrf = checkedStatistic.filter(domainVrf => domainVrf.status.id == 1)

    let arrayCheckedStatistic = []
    arrayCheckedStatisticVrf.map(chkd => arrayCheckedStatistic.push({
      'name': chkd.url,
      'id': chkd.id,
      'checked': true
    }))

    state.checkedStatistic = arrayCheckedStatistic
  },
  set: (state, domains) => {
    state.domains = domains
  },
  drop: (state, id) => {
    var newDomains = []
    state.domains.map(e => {
      if (e.id != id) {
        newDomains.push(e)
      }
    })
    state.domains = newDomains
  },
  setVrfDomain: (state, vrfDomain) => {
    state.vrfDomain = vrfDomain
  },
  setDomainStatus: (state, domainStatus) => {
    state.domainStatus = domainStatus
  },
  setDomainHash: (state, domainHash) => {
    state.domainHash = domainHash
  },
  setDataDomainVerifi: (state, dataDomainVerifi) => {
    state.dataDomainVerifi = dataDomainVerifi
  }
};

const actions = {
  getHashDomain: ({commit}, hash) => {
    if (hash != null) {
      commit('setDomainHash', hash)
    }
  },
  getDataDomainVerifi: ({commit}, dataDomain) => {
    commit('setDataDomainVerifi', dataDomain)
  },
  find: ({commit}, payload) => {
    return new Promise((resolve) => {
      Http({
        method: 'post',
        url: Config.apiURL + '/v1/user/team/domain/search',
        data: payload,
        headers: {'Content-Type': 'application/json'},
      })
        .then(resp => {
          commit('set', resp.data)
          commit('setCheckedStatistic', resp.data)
          resolve(resp)
        })
        .catch(err => {
          console.log('error find domain ' + err)
        })
    })
  },
  createdDomain: ({commit}, addDomain) => {
    return new Promise((resolve) => {
      Http({
        method: 'post',
        url: Config.apiURL + '/v1/user/team/' + addDomain.team_id + '/domain/create',
        data: addDomain,
        headers: {'Content-Type': 'application/json'},
      }).then(resp => {
        commit('set', resp.data)
        commit('app/setMessagesEvent', resp.message, {root: true})
        resolve(resp)
      }).catch(err => {
        commit('app/setMessagesEvent', err.response.data.error.message, {root: true})
      })
    })
  },
  deletedDomains: ({commit}, domain) => {
    return new Promise((resolve) => {
      Http({
        method: 'delete',
        url: Config.apiURL + '/v1/user/team/' + domain.teamId + '/domain/' + domain.id,
        data: domain,
        headers: {'Content-Type': 'application/json'},
      }).then(resp => {
        commit('drop', domain.id)
        resolve(resp)
      }).catch(err => {
        console.log('ОШИБКА УДАЛЕНИЯ ДОМЕНА ' + err)
      })
    })
  },
  verifyDomain: ({commit}, methodDomain) => {
    return new Promise((resolve) => {
      Http({
        method: 'put',
        url: Config.apiURL + '/v1/user/team/' + methodDomain.team_id + '/domain/' + methodDomain.domain_id + '/verify',
        data: methodDomain,
        headers: {'Content-Type': 'application/json'},
      }).then(resp => {
        commit('setVrfDomain')
        commit('app/setMessagesEvent', resp.message, {root: true})
        resolve(resp)
      }).catch(err => {
        commit('app/setMessagesEvent', err.response.data.error.message, {root: true})
      })
    })
  },
  onDomainStatus: ({commit}, domain) => {
    return new Promise((resolve) => {
      Http({
        method: 'patch',
        url: Config.apiURL + '/v1/user/team/' + domain.teamId + '/domain/' + domain.id + '/on',
        headers: {'Content-Type': 'application/json'},
      }).then(resp => {
        commit('setDomainStatus')
        commit('app/setMessagesEvent', resp.message, {root: true})
        resolve(resp)
      }).catch(err => {
        commit('app/setMessagesEvent', err.response.data.error.message, {root: true})
      })
    })
  },
  offDomainStatus: ({commit}, domain) => {
    return new Promise((resolve) => {
      Http({
        method: 'patch',
        url: Config.apiURL + '/v1/user/team/' + domain.teamId + '/domain/' + domain.id + '/off',
        headers: {'Content-Type': 'application/json'},
      }).then(resp => {
        commit('setDomainStatus')
        commit('app/setMessagesEvent', resp.message, {root: true})
        resolve(resp)
      }).catch(err => {
        commit('app/setMessagesEvent', err.response.data.error.message, {root: true})
      })
    })
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
