<template>
  <div class="sing-up">
    <div class="register-card">
      <div class="logo">
        <img src="../images/svg/logo-icon-bg-blue60.svg" alt="logo"/>
      </div>
      <div class="form-register">
        <form @submit.prevent="registration">
          <div class="form-register-content">
            <span class="form-register-title font-h2 color_white">{{ localize('registration_title') }}</span>
            <div class="input-field">
              <input
                class="input-email"
                id="email-register"
                type="text"
                v-model="email"
                :placeholder="localize('login_email_input')"
                :class="{invalid: ($v.email.$dirty && !$v.email.required) || ($v.email.$dirty && !$v.email.email)}">
              <label class="visually-hidden" for="email-register">{{ localize('registration_email_input') }}</label>
            </div>
            <div class="block-error">
              <div class="text-error">{{ registerMessages() }}</div>
              <span class="text-error" v-if="$v.email.$dirty && !$v.email.required">{{ localize('registration_invalid-field') }}</span>
              <span class="text-error" v-if="$v.email.$dirty && !$v.email.email" >{{ localize('registration_invalid-email') }}</span>
            </div>
          </div>
          <div class="form-register-action">
            <div class="form-register-btn">
              <button class="button button-white">{{ localize('registration_submit_application') }}
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="socials">
        <ul>
          <li><a :href="linkGoogleRegisterDef" @click="googleRegister()"><svg><use xlink:href="../images/sprite/sprite-socials.svg#google"></use></svg></a></li>
          <li><a :href="linkGitHubRegisterDef" @click="githubRegister()"><svg><use xlink:href="../images/sprite/sprite-socials.svg#github"></use></svg></a></li>
        </ul>
      </div>
      <div class="link-register">
        <span>{{ localize('registration_dont_have_account') }}</span>
        <router-link
          class="links-white"
          to="/login"
        >{{ localize('registration_sign_in') }}
        </router-link>
      </div>
    </div>
    <div class="background-img">
      <div class="logo">
        <img src="../images/png/logo-icon-text.png" alt="logo tlprt-media">
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/filters/localize";
import {email, required} from 'vuelidate/lib/validators'
import Vuex from 'vuex'

export default {
  mixins: [i18n],
  name: 'register',
  data() {
    return {
      email: '',
      messages: '',
      linkGoogleRegisterDef: '#',
      linkGitHubRegisterDef: '#'
    }
  },
  validations: {
    email: {email, required}
  },
  computed: {
    ...Vuex.mapGetters({
      linkGoogleRegister: 'auth/linkGoogleRegister',
      linkGitHubRegister: 'auth/linkGitHubRegister',
    })
  },
  watch: {
    linkGoogleRegister() {
      window.location.href = this.linkGoogleRegister.data
    },
    linkGitHubRegister() {
      window.location.href = this.linkGitHubRegister.data
    }
  },
  methods: {
    ...Vuex.mapGetters({
      error: 'app/error',
    }),
    ...Vuex.mapActions({
      registry: 'auth/registry',
      getGoogleRegister: 'auth/googleRegister',
      getGitHubRegister: 'auth/githubRegister'
    }),
    registration() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.registry({
        email: this.email,
        serviceURLForMailMessage: origin
      })
        .then(() => {
          this.$router.push('/endRegister')
        })
    },
    googleRegister() {
      let URL = 'https://gteams.tlprt.cloud/api/guest/registration/google/callback'
      let clientURL = `${origin}/account`
      this.getGoogleRegister({
        callbackUrl: URL,
        redirectUrl: clientURL
      })
    },
    githubRegister() {
      let URL = 'https://gteams.tlprt.cloud/api/guest/oauth/github/callback/registration'
      let clientURL = `${origin}/account`
      this.getGitHubRegister({
        callbackUrl: URL,
        redirectUrl: clientURL
      })
    },
    registerMessages() {
      return this.messages = this.error()
    }
  }
}
</script>
