import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import i18n from './i18n'
import domains from "./domains";
import dictionaries from "./dictionaries";
import app from './app'
import role from './role'
import teams from './teams'
import keys from './keys'
import statistics from './statistics'

Vue.use(Vuex)

let store = new Vuex.Store({
  modules: {
    auth,
    i18n,
    domains,
    dictionaries,
    app,
    role,
    teams,
    keys,
    statistics
  }
})

export default store
