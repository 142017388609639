import Http from "../utils/http";
import Config from "../config";

const state = {
  countries: [],
  currencyes: [],
  roles: [],
  statuses: [],
  validationMethods: []
};

const getters = {
  countries: state => state.countries,
  currencyes: state => state.currencyes,
  roles: state => state.roles,
  statuses: state => state.statuses,
  validationMethods: state => state.validationMethods
};

const mutations = {
  setCountries: (state, countries) => {
    state.countries = countries
  },
  setCurrencyes: (state, currencyes) => {
    state.currencyes = currencyes
  },
  setRoles: (state, roles) => {
    state.roles = roles
  },
  setStatuses: (state, statuses) => {
    state.statuses = statuses
  },
  setValidationMethods: (state, validationMethods) => {
    state.validationMethods = validationMethods
  }
};

const actions = {
  find: ({commit}) => {
    return new Promise((resolve) => {
      Http.get(Config.apiURL + '/dictionaries/45')
        .then(resp => {
          commit('setCountries', resp.data.countries_ISO)
          commit('setCurrencyes', resp.data.currency_ISO)
          commit('setRoles', resp.data.team_roles)
          commit('setStatuses', resp.data.team_statuses)
          commit('setValidationMethods', resp.data.validation_methods)
          resolve(resp)
        })
        .catch(err => {
          console.log(err)
        })
    })
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
