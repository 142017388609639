<template>
  <div class="error">
    <div class="content">
      <div class="text">
        {{errorMessages}}
      </div>
      <router-link to="/login" class="button button-white gray">back</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'error',
  data() {
    return {
      errorMessages: null
    }
  },
  mounted() {
    this.errorMessages = this.$route.query.error
  }
}
</script>
