const state = {
    message: null,
    error: null,
    messagesEvent: null
}

const getters = {
    message: state => state.message,
    error: state => state.error,
    messagesEvent: state => state.messagesEvent
}

const mutations = {
    setMessage: (state, message) => {
        state.message = message
    },
    setError: (state, error) => {
        state.error = error
    },
    setMessagesEvent: (state, messagesEvent) => {
        state.messagesEvent = messagesEvent
    }
}

const actions = {}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
