<template>
    <div class="content-wrapper d-flex"> <!-- v-if="team.id > 0"-->
      Макет в разработке дизайнером
      <!--<IntegrationB />-->
    </div>
</template>
<script>
import Vuex from "vuex";
// import IntegrationB from '@/components/Integration/Integration/IntegrationB'

export default {
    name: 'integration',
    // components: {
    //     IntegrationB
    // },
    computed: {
        ...Vuex.mapGetters({
            team: 'teams/selectedTeam',
        })
    },
}
</script>
