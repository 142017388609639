<template>
  <div>
    <div class="password-recovery">
      <div class="password-recovery_card">
        <div class="logo">
          <img src="../images/svg/logo-icon-bg-blue60.svg" alt="logo"/>
        </div>
        <div class="form_password-recovery">
          <form @submit.prevent="passwordRecovery">
            <div class="form_password-recovery-content">
              <span class="form-_password-recovery-title" v-if="registerPassword">{{ localize('restore_title_new_password') }}</span>
              <span class="form-_password-recovery-title" v-if="requestPassword">{{ localize('restore_title_reset_password') }}</span>
              <div class="input-field">
                <input
                  class="input-password"
                  id="password-recovery"
                  type="password"
                  v-model="password"
                  :placeholder="localize('password-recovery_input-password')"
                  :class="{invalid:($v.password.$error && !$v.password.required) || ($v.password.$error && !$v.password.minLength)}">
                <label class="visually-hidden" for="password-recovery">{{localize('password-recovery_input-password')}}</label>
              </div>
              <div class="block-error">
                <span class="text-error" v-if="$v.password.$error && !$v.password.required">{{localize('password-recovery_invalid-field')}}</span>
                <span class="text-error" v-if="$v.password.$error && !$v.password.minLength">{{localize('password-recovery_invalid-minLength')}}</span>
              </div>

              <div class="input-field">
                <input
                  class="input-password"
                  id="repeatPassword-recovery"
                  type="password"
                  v-model="confirmPassword"
                  :placeholder="localize('password-recovery_input-reaped-password')"
                  :class="{ invalid: ($v.confirmPassword.$error && !$v.confirmPassword.required) || ($v.confirmPassword.$error && !$v.confirmPassword.minLength)}">
                <label class="visually-hidden" for="password-recovery">{{localize('password-recovery_input-reaped-password')}}</label>
              </div>
              <div class="block-error">
                <span class="text-error" v-if="$v.confirmPassword.$error && !$v.confirmPassword.required">{{ localize("password-recovery_invalid-sameAs") }}</span>
              </div>
            </div>
            <div class="form_password-recovery-action">
              <div class="form_password-recovery-btn">
                <button class="button button-white font-button">{{ localize('registration_submit_application') }}
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="link_password-recovery">
          <span>{{ localize('registration_dont_have_account') }}</span>
          <router-link class="links-white" to="/login">{{localize('registration_sign_in')}}</router-link>
        </div>
      </div>
      <div class="background-img">
        <div class="logo">
          <img src="../images/png/logo-icon-text.png" alt="logo tlprt-media">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/filters/localize";
import {required, sameAs, minLength} from 'vuelidate/lib/validators'
import Vuex from 'vuex'

export default {
  mixins: [i18n],
  name: 'passwordRecovery',
  data() {
    return {
      password: '',
      confirmPassword: ''
    }
  },
  validations: {
    password: {
      required,
      minLength: minLength(6)
    },
    confirmPassword: {
      sameAsPassword: sameAs('password')
    }
  },
  computed: {
    ...Vuex.mapGetters({
      registerPassword: 'auth/registerMessage',
      requestPassword: 'auth/requestPasswordMessage'
    })
  },
  methods: {
    ...Vuex.mapGetters({
      error: 'app/error',
    }),
    ...Vuex.mapActions({
      changePassword: 'auth/changePassword',
    }),
    passwordRecovery() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.changePassword({
        'hash': this.$route.params.hash,
        "password": this.password,
        "passwordConfirmation": this.confirmPassword,
      })
        .then(() => {
          this.password = ''
          this.confirmPassword = ''
          this.$router.push('/account')
        })
    },
    passwordRecoveryMessages() {
      return this.messages = this.error()
    }
  },
}
</script>
