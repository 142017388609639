import store from "@/store";
import en from '@/locales/en.json'
import ru from '@/locales/ru.json'

const locales = {
  '45': en,
  '643': ru
}

export default {
  methods: {
    localize(key) {

      const locale = store.getters['i18n/locale']

      if (locale == null) {
        return
      }

      return locales[locale][key]
    }
  }
}
