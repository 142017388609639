import Config from "../config";
import Http from "@/utils/http";

const state = {
  apiURL: Config.apiURL,
  roles: [],
}

const getters = {
  apiURL: state => state.apiURL,
  roles: state => state.roles,

}

const mutations = {
  setRoles: (state, roles) => {
    state.roles = roles
  },
}

const actions = {
  createRole: ({commit}, dataRole) => {
    return new Promise((resolve) => {
      Http({
        method: 'post',
        url: Config.apiURL + '/v1/user/team/' + dataRole.teamId + '/user/' + dataRole.userId + '/roles',
        headers: {'Content-Type': 'application/json'},
        data: dataRole.roles
      })
        .then(resp => {
          commit('setRoles')
          resolve(resp)
        })
        .catch(err => {
          commit('app/setMessagesEvent', err.response.data.error.message, {root: true})
        })
    })
  },
  deleteRole: ({commit}, dataRole) => {
    return new Promise((resolve) => {
      Http({
        method: 'delete',
        url: Config.apiURL + '/v1/user/team/' + dataRole.teamId + '/user/' + dataRole.userId + '/roles',
        headers: {'Content-Type': 'application/json'},
        data: dataRole.roles
      })
        .then(resp => {
          commit('setRoles')
          resolve(resp)
        })
        .catch(err => {
          commit('app/setMessagesEvent', err.response.data.error.message, {root: true})
        })
    })
  },
  excludeUser: ({commit}, dataExcluded) => {
    return new Promise((resolve) => {
      Http({
        method: 'delete',
        url: Config.apiURL + '/v1/user/team/' + dataExcluded.teamId + '/exclude/' + dataExcluded.excludedUserId,
        headers: {'Content-Type': 'application/json'}
      })
        .then(resp => {
          resolve(resp)
          commit('app/setMessagesEvent', resp.message, {root: true})
        })
        .catch(err => {
          commit('app/setError', err.response.data.error.message, {root: true})
          commit('app/setMessagesEvent', err.response.data.error.message, {root: true})
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
