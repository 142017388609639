<template>
  <div class="add-domains-inner">

    <modal-deleted-domain v-if="isModalDeletedDomain" />

    <div class="add-domain">

      <div class="add-domain__validations">
        <div class="domain__validations-title">
          <span class="font-h5 color_dark-gray">Add the domain, where you're going to use Teleport Media 777</span>
        </div>
        <form class="domain__validations-form">
          <div class="domain__validations-input">
            <label class="visually-hidden" for="domain__validations-input">Enter your domain name</label>
            <input class="input" type="text" id="domain__validations-input" placeholder="Enter your domain name"
                   required> <!--  input--invalid -->
            <span class="input-check">
              <svg>
                <use xlink:href="../../images/sprite/sprite-domains.svg#check"></use>
              </svg>
            </span>
            <!--            <button class="input&#45;&#45;close" type="submit">-->
            <!--              <svg>-->
            <!--                <use xlink:href="../../images/sprite/sprite-header.svg#delete-draft-domain"></use>-->
            <!--              </svg>-->
            <!--            </button>-->
            <span class="input--invalid-message font-small-2 color_coral">Неверный формат домена</span>
          </div>
          <div class="domain__validations-check">
            <button class="button button-blue-w color_white">Check</button>
          </div>
        </form>
      </div>

      <div class="add-domain__confirm">
        <div class="confirm-number color_state-gray">1</div>
        <div class="confirm-block">
          <div class="block__title">
            <span
              class="font-h5 color_dark-gray">Please confirm the domain ownership by one of the following ways:</span>
          </div>
          <div class="block__tabs">
            <div
              class="block__tab font-small color_gray"
              :class="{'block__tab--active': tabActive === 'meta'}"
              @click="tabActiveited('meta')">META
            </div>
            <div
              class="block__tab font-small color_gray"
              :class="{'block__tab--active': tabActive === 'html'}"
              @click="tabActiveited('html')">HTML
            </div>
            <div
              class="block__tab font-small color_gray"
              :class="{'block__tab--active': tabActive === 'dns'}"
              @click="tabActiveited('dns')">dnsTXT
            </div>
            <div
              class="block__tab font-small color_gray"
              :class="{'block__tab--active': tabActive === 'cname'}"
              @click="tabActiveited('cname')">CNAME
            </div>
          </div>
          <div v-if="tabActive === 'meta'" class="block__tab-content font-small color_gray">
            Add the tag
            <span class="color_dodger-blue d-block">&lt;meta name="teleport-site-verification" content="Hr+cKMs9EqcghU7NrCaqj/SEdUwd2RVg1eAsl5jfwHE=" /&gt;</span>
            inside the &lt;head&gt; tag on the main page of your site.
          </div>
          <div v-if="tabActive === 'html'" class="block__tab-content block__tab-content-pt font-small color_gray">
            Paste the file named <span class="color_dodger-blue">teleport91cd44dfa6451f5f.html</span><br>
            with the text <span class="color_dodger-blue">teleport-site-verification:Hr+cKMs9EqcghU7NrCaqj/SEdUwd2RVg1eAsl5jfwHE=</span>
          </div>
          <div v-if="tabActive === 'dns'" class="block__tab-content block__tab-content-pt  font-small color_gray">
            Log in to your hosting provider account and add the entry
            <span class="color_dodger-blue d-block">teleport-site-verification=Hr+cKMs9EqcghU7NrCaqj/SEdUwd2RVg1eAsl5jfwHE=</span>
          </div>
          <div v-if="tabActive === 'cname'" class="block__tab-content block__tab-content-pt  font-small color_gray">
            Set the CNAME-record for the subdomain <span class="color_dodger-blue">d41d8cd9</span>. of your domain to
            <span class="color_dodger-blue d-block">b3cd4bf9428c.tlprthosting.cloud</span>
          </div>
          <div class="block-btn">
            <button class="button button-blue color_white">Verify my domain</button>
          </div>
        </div>
      </div>

      <div class="add-domain__script">
        <div class="script-number color_state-gray">2</div>
        <div class="script__block">
          <div class="block__title">
            <span class="font-h5 color_dark-gray">Place this Teleport script on your site:</span>
            <p class="font-small color_gray">Place the Teleport script inside the &lt;body&gt; tag just before the video
              player scripts on every page
              <br>with the player or in the template of your site pages as shown in the sample:</p>
          </div>
          <div class="block__content font-small color_gray">
            <span class="d-block">&lt;body&gt;</span>
            <span
              class="color_dodger-blue d-block">&lt;script src="http://tlprt.cloud/teleport.js"&gt;&lt;script&gt;</span>
            <span class="color_dodger-blue d-block">&lt;script src="http://vjs.zencdn.net/5.8.8/video.js"&gt;&lt;script&gt;</span>
            <span class="d-block">&lt;/body&gt;</span>
          </div>
          <div class="block__links">
            <a class="font-button" href="#">
              Go to statistics
              <svg>
                <use xlink:href="../../images/sprite/sprite-domains.svg#arrow-right"></use>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalDeletedDomain from "@/components/Modales/modal-deleted-domain";

export default {
  components: {ModalDeletedDomain},
  data() {
    return {
      tabActive: 'meta',
      isModalDeletedDomain: false
    }
  },
  methods: {
    tabActiveited(tag) {
      this.tabActive = tag
    }
  }
}
</script>
