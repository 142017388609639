<template>
  <div class="main-wrapper">
    <div class="sitebar">
      <div class="sitebar-inner">
        <div class="logo">
          <img src="../images/svg/logo-icon-bg-blue40.svg" alt="logo"/>
        </div>
        <div class="name-workspace">
          <span class="font-h5 color_white">Name Workspace</span>
        </div>
        <div class="sitebar-list">
          <div class="sitebar-item">
            <router-link
              to="/statisticsSummary"
              class="sitebar-item-links"
              :class="{'link-active': isActive($router.options.routes,'statisticsSummary', $route.name)}"
            >
              <svg>
                <use xlink:href="../images/sprite/sprite-navbar.svg#n-statistic"></use>
              </svg>
              <span @click="isActiveMenu('statisticsSummary')" class="font-menu">{{ localize('sitebar_statistics') }}</span>
            </router-link>
          </div>
          <div class="sitebar-item">
            <router-link
              to="/integration"
              class="sitebar-item-links"
              :class="{'link-active': $route.name == 'integration' || $route.name == 'addDomains'}"
            >
              <svg>
                <use xlink:href="../images/sprite/sprite-navbar.svg#n-integration"></use>
              </svg>
              <span @click="isActiveMenu('integration')" class="font-menu">{{ localize('sitebar_integration') }}</span>
              <span class="sitebar-item-icon" @click="isActiveMenu('integration')">
                 <svg>
                    <use xlink:href="../images/sprite/sprite-navbar.svg#arrow-icon-menu"></use>
                 </svg>
              </span>
            </router-link>
            <transition name="fade">
              <div v-if="isActiveMenuTag == 'integration'" class="sitebar-item-list">
                <ul>
                  <li><router-link to="/integration" class="font-submenu color_white">{{ localize('navbar-integration_summary') }}</router-link></li>
                  <li><router-link to="/addDomains" class="font-submenu color_white">{{ localize('navbar-integration_add-domain') }}</router-link></li>
                </ul>
              </div>
            </transition>
          </div>
          <div class="sitebar-item">
            <router-link
              to="/billing"
              class="sitebar-item-links"
              :class="{'link-active': isActive($router.options.routes,'billing', $route.name)}">
              <svg>
                <use xlink:href="../images/sprite/sprite-navbar.svg#n-billing"></use>
              </svg>
              <span @click="isActiveMenu('billing')" class="font-menu">Billing</span>
            </router-link>
          </div>
          <div class="sitebar-item">
            <router-link
              to="/account"
              class="sitebar-item-links"
              :class="{'link-active':
              $route.name == 'account' ||
              $route.name == 'notifications' ||
              $route.name == 'profileDetails' ||
              $route.name == 'profileSecurity'}">
              <svg>
                <use xlink:href="../images/sprite/sprite-navbar.svg#n-account"></use>
              </svg>
              <span @click="isActiveMenu('account')" class="font-menu">{{ localize('sitebar_account') }}</span>
            </router-link>
          </div>
          <div class="sitebar-item">
            <router-link
              to="/teams"
              class="sitebar-item-links"
              :class="{'link-active': isActive($router.options.routes,'teams', $route.name)}">
              <svg>
                <use xlink:href="../images/sprite/sprite-navbar.svg#n-teams"></use>
              </svg>
              <span @click="isActiveMenu('teams')" class="font-menu">Team</span>
            </router-link>
          </div>
        </div>
      </div>
      <div class="sitebar-footer-inner">
        <div class="banner">
          <a href="#">
            <img src="../images/png/banner5.png" alt="">
          </a>
        </div>
        <div class="logout">
          <a @click="logout">
            <svg class="navbar-icon">
              <use xlink:href="../images/sprite/sprite-navbar.svg#n-logout"></use>
            </svg>
            <span class="font-menu">{{ localize('sitebar_logout') }}</span>
          </a>
        </div>
      </div>
    </div>

    <headerTop/>

    <router-view class="main-screen"></router-view>

  </div>
</template>

<script>
import Vuex from "vuex";
import i18n from "@/filters/localize";
import isActive from "@/router/activeLink";
import headerTop from "@/components/Header"

export default {
  mixins: [i18n, isActive],
  name: 'mainScreen',
  data() {
    return {
      isActiveMenuTag: null,
    }
  },
  components: { headerTop },
  computed: {
    ...Vuex.mapGetters({
      IsAuthenticated: 'auth/isAuthenticated'
    })
  },
  methods: {
    ...Vuex.mapActions({
      getLogout: 'auth/logout',
    }),
    logout() {
      this.getLogout()
        .then(() => {
          this.$router.push('/login')
          location.reload()
        })
    },
    isActiveMenu(tag) {
      this.isActiveMenuTag = (this.isActiveMenuTag === tag) ? null : tag
    }
  },
  created() {
    if (this.$route.path === '/main') {
      this.$router.push('/integration')
    }
  }
}
</script>
