<template>
  <div class="sing-in">
    <div class="auth-card">
      <div class="logo">
        <img src="../images/svg/logo-icon-bg-blue60.svg" alt="logo"/>
      </div>
      <div class="form-login">
        <form @submit.prevent="auth">
          <div class="form-login-content">
            <span class="form-login-title font-h2 color_white">{{ localize('login_title') }}</span>
            <div class="input-field">
              <input
                class="input-email"
                id="email"
                type="text"
                v-model="email"
                :placeholder="localize('login_email_input')"
                :class="{invalid: ($v.email.$dirty && !$v.email.required) || ($v.email.$dirty && !$v.email.email)}"/>
              <label class="visually-hidden" for="email">{{ localize('login_email_input') }}</label>
            </div>
            <div class="block-error">
              <span class="text-error" v-if="$v.email.$dirty && !$v.email.required">{{localize('login_invalid-field')}}</span>
              <span class="text-error" v-if="$v.email.$dirty && !$v.email.email">{{localize('login_invalid-email')}}</span>
            </div>
            <div class="input-field">
              <input
                class="input-password"
                id="password"
                v-model="password"
                type="password"
                :placeholder="localize('login_password_input')"
                :class="{invalid: ($v.password.$dirty && !$v.password.required) || ($v.password.$dirty && !$v.password.minLength),}"/>
              <label class="visually-hidden" for="password">{{ localize('login_password_input') }}</label>
            </div>
            <div class="block-error">
              <span class="text-error" v-if="$v.password.$dirty && !$v.password.required">{{localize('login_invalid-field')}}</span>
              <span class="text-error" v-if="$v.password.$dirty && !$v.password.minLength">{{localize('login_invalid-email')}}</span>
            </div>
          </div>
          <div class="form-login-action">
            <div class="form-login-btn">
              <button class="button button-white font-button">{{ localize('login_login') }}</button>
            </div>
            <div class="form-login-reset">
              <router-link to='/restore' title="">{{ localize('login_forgot_password') }}</router-link>
            </div>
          </div>
          <span class="text-error" v-if="true">{{ loginMessages() }}</span>
        </form>
      </div>
      <div class="socials">
        <ul>
          <li><a :href="linkGoogleAuth" @click="googleAuth()"><svg><use xlink:href="../images/sprite/sprite-socials.svg#google"></use></svg></a></li>
          <li><a :href="linkGitHubAuth" @click="githubAuth()"><svg><use xlink:href="../images/sprite/sprite-socials.svg#github"></use></svg></a></li>
        </ul>
      </div>
      <div class="link-register">
        <span>{{ localize('login_dont_have_account') }}</span>
        <router-link class="links-white" to="/register">{{ localize('login_sign_up_for_free') }}</router-link>
      </div>
    </div>
    <div class="background-img">
      <div class="logo">
        <img src="../images/png/logo-icon-text.png" alt="logo tlprt-media">
      </div>
    </div>
  </div>
</template>

<script>

import i18n from "@/filters/localize";
import Vuex from "vuex";
import {email, required, minLength} from 'vuelidate/lib/validators'

export default {
  name: 'login',
  mixins: [i18n],
  data: () => ({
    email: '',
    password: '',
    message: null,
    linkGoogleAuth: '#',
    linkGitHubAuth: '#'
  }),
  validations: {
    email: {email, required},
    password: {required, minLength: minLength(6)},
  },
  computed: {
    ...Vuex.mapGetters({
      linkGoogle: 'auth/linkGoogle'
    }),
  },
  watch: {
    linkGoogle() {
      window.location.href = this.linkGoogle.data
    },
  },
  methods: {
    ...Vuex.mapGetters({
      error: 'app/error',
      isAuthenticated: 'auth/isAuthenticated',
    }),
    ...Vuex.mapActions({
      logInGoogle: 'auth/googleAuth',
      logInGitHub: 'auth/githubAuth',
      logIn: 'auth/auth'
    }),

    auth() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.logIn({email: this.email, password: this.password})
      .then(() => {
        this.$router.push('/integration')
      })
      .catch(() => {
        console.log('login error')
      })
    },
    googleAuth() {
      let URL = 'https://gteams.tlprt.cloud/api/guest/oauth/google/callback'
      let clientURL = `${origin}/account`
      this.logInGoogle({
        callbackUrl: URL,
        clientRedirectUrl: clientURL
      })
    },
    githubAuth() {
      let URL = 'https://gteams.tlprt.cloud/api/guest/oauth/github/callback'
      let clientURL = `${origin}/account`
      this.logInGitHub({
        callbackUrl: URL,
        clientRedirectUrl: clientURL
      })
    },
    loginMessages() {
      return this.messages = this.error()
    }
  },
  mounted() {
    this.isAuthenticated ? this.$router.push('/integration') : this.$router.push('/')
  }
};
</script>
