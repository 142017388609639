<template>
  <div class="header-top">
    <div class="header-wrapper">
      <div class="" v-if="false"></div>
      <div v-else class="add-domains_draft">
        <ul>
          <li class="font-text-medium domains_draft--active">
            <span>new domain</span>
            <svg>
              <use xlink:href="../images/sprite/sprite-header.svg#delete-draft-domain"></use>
            </svg>
          </li>
          <li class="font-text-medium">
            <span>new domain</span>
            <svg>
              <use xlink:href="../images/sprite/sprite-header.svg#delete-draft-domain"></use>
            </svg>
          </li>
          <li class="font-text-medium">
            <span>new domain</span>
            <svg>
              <use xlink:href="../images/sprite/sprite-header.svg#delete-draft-domain"></use>
            </svg>
          </li>
          <li class="font-text-medium">
            <span>new domain</span>
            <svg>
              <use xlink:href="../images/sprite/sprite-header.svg#delete-draft-domain"></use>
            </svg>
          </li>
        </ul>
        <button class="btn_add-domain">
          <svg>
            <use xlink:href="../images/sprite/sprite-header.svg#plus-add"></use>
          </svg>
        </button>
      </div>
      <div class="menu">
        <div class="docs menu-item">
          <svg>
            <use xlink:href="../images/sprite/sprite-header.svg#docs"></use>
          </svg>
        </div>
        <div class="question menu-item">
          <svg>
            <use xlink:href="../images/sprite/sprite-header.svg#question"></use>
          </svg>
        </div>
        <div class="notifications menu-item">
          <router-link to="/notifications">
            <svg>
              <use xlink:href="../images/sprite/sprite-header.svg#notification"></use>
            </svg>
          </router-link>
          <div
            :class="{'notification__circle--active': true}"
            class="notification__circle">
          </div>
        </div>
        <div class="user menu-item">
          <svg>
            <use xlink:href="../images/sprite/sprite-header.svg#user-images-default"></use>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header"
}
</script>

